#modal-container {
    width: 300px;
    z-index: 100;
    position: absolute;
    top:350px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.arrow-button {
  border: none;
  margin-top: 10px;
  background: none;
}

.arrow-button-hide {
  visibility: hidden;
  
}
