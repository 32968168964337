.establishment-list-box {
  width: 100%;
  height: 450px;
  border: 1px solid #aaa;
  overflow-y: auto;
  overflow-x: hidden;
}

ul.establishment-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

li.establishment-list-item {
  padding-left: 20px;
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #fff;
  cursor: pointer;
}

li.selected {
  padding-bottom: 5px;
  padding-top: 5px;
  background-color: #ccc;
}

.subscription-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-button-div {
  position: relative;
  top: 2px;
}