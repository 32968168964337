.tdis_keyCopyContainer {
  display: flex;
  align-items: center;
  padding-bottom: 8px;
}

.tdis_keyDiv {
  width: 90%;
  overflow-x: scroll;
  border: 1px solid #EEE;
  padding: 4px;
}

.tdis_copyDiv {
  padding-left: 8px;
}

.tdis_alert {
 position: absolute;
 left: 40%; 
 opacity: 0;
}

@keyframes fadeIn {
  0%, 100% { opacity: 0; }
  40%, 50%, 60% { opacity: .75; }
}

.tdis_fade_in_image {
  animation: fadeIn 5s;
}

.tdis-saved-pubkey-ta {
  width: 100%;
  height: 90px;
  resize: none;
}

.tdis-status-div {
  padding-top: 10px;
}