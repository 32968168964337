.welcome_screenshot {
  border: 1px solid #CCC;
  max-width: 80%;
  height: auto;
  box-shadow: 5px 5px 20px #999;
}

.bottom-space-lg {
  margin-bottom: 24px;
}

.bottom-space-md {
  margin-bottom: 12px;
}