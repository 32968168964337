#google-map {
  height: 600px;
  width: 100%;
  background-color: bisque;
  border: 1px solid #aaa;
}

#establishment-list {
  min-width: 300px;
  height: 600px;
  border: 1px solid #aaa;
  border-right: 0px;
  overflow: scroll;
}

#establishment-list::-webkit-scrollbar {
  display: none;
}

#list-map {
  margin-top: 20px;
  display: flex;
}

.comp-group-title {
  margin-top: 8px;
}

.select-peer-display {
  margin-top: 20px;
}

.peer-display {
  margin-top: 11px;
  display: flex;
  flex-wrap: wrap;
}

.peer-button {
  padding-right: 5px;
}

.peer-button-group {
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: 3px;
}

.peer-remove-button {
  position: relative;
  left: -4px;
}
