.center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loading-logo {
  position: relative;
  left: -16px;
}

.loading-loop {
  position: relative;
  top: -10px;
}