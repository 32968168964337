.rr-brand-div {
  display: flex;
  flex-wrap: nowrap;
  align-items:center;
  height: 95px;
  margin-top: 10px;
}

.rr-brand-div > p {
  vertical-align: middle;
  font-weight: bold;
  color:#555;
  margin-left:10px;
}

.rr-brand-bar {
  height: 25px;
  width:100%;
  background-color: #666;
  border-radius: 8px;
  padding-top:5px;
  padding-bottom:3px;
  padding-right:15px;
  font-size: medium;
  font-weight: bold;
  flex-wrap: nowrap;
  display: flex;
  justify-content: flex-end;
}

.rr-brand-bar a {
  color: #fff;
  text-decoration: none;
}

.rr-brand-bar a:hover {
  text-decoration: underline;
}

.rr-brand-bar span {
  margin-left: 5px;
  margin-right: 5px;
  color:#fff;
}
